<template lang="html">
	<div class="footer black">
		<v-layout column>
			<v-flex>
				<v-layout row wrap align-start>
					<v-flex xs12 sm12 md4 :class="{'mb-5':$vuetify.breakpoint.smAndDown}">
						<v-layout column class="footer-content-wrapper">
							<v-flex style="margin-bottom:10px;">
								<div style="width:150px">
									<Logo color="white" />
								</div>
							</v-flex>
							
							<v-flex style="font-size:14px;margin-top:10px">
								<v-layout column>
									<v-flex class="accent--text imagine-ftr-txt" mb-4>
										A wholly owned subsidiary of ValueLabs
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 sm12 md8>
						<v-layout row wrap>
							<v-flex xs12 sm6 md4 style="font-size:18px;" :class="{'px-5':$vuetify.breakpoint.mdAndUp, 'my-5':$vuetify.breakpoint.smAndDown}">
								<v-layout column>
								<v-flex class="white--text">
										<a class="white--text imagine-ftrAddr-txt" target="_blank" href="https://www.google.com/maps?ll=33.963817,-84.119983&z=17&t=m&hl=en-US&gl=IN&mapclient=embed&cid=3489292757611401013">
											ValueLabs, Inc.<br />3235 Satellite Blvd,<br />Building 400, Suite 300,<br />Duluth, GA 30096, US
										</a>
									</v-flex>
								<!--<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										Imagine Tech
									</v-flex>
									<v-flex v-for="(product, i) in products" :key="i">
										<router-link :to="product.path" class="white--text">{{ product.name }}</router-link>
									</v-flex>-->
								</v-layout>
							</v-flex>
							<v-flex xs12 sm6 md4 style="font-size:18px;" :class="{'px-5':$vuetify.breakpoint.mdAndUp, 'my-5':$vuetify.breakpoint.smAndDown}">
								<v-layout column class="ftr-navLink-wrapper">
								<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										<a @click="scrollMeTo('about')" class="ftrNav-links-imagine">About</a>
									</v-flex>
									<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										<a @click="scrollMeTo('solutions')" class="ftrNav-links-imagine">Solutions</a>
									</v-flex>
									<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										<a @click="scrollMeTo('contact')" class="ftrNav-links-imagine">Contact</a>
									</v-flex>
									<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										<router-link to="/privacy-policy" class="ftrNav-links-imagine">Privacy Policy</router-link>
									</v-flex>
									<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										<router-link to="/terms-of-use" class="ftrNav-links-imagine">Terms Of Use</router-link>
									</v-flex>
								<!--<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										Industry
									</v-flex>
									<v-flex v-for="(industry, i) in industries" :key="i">
										<router-link :to="industry.path" class="white--text">{{ industry.title }}</router-link>
									</v-flex>-->
								</v-layout>
							</v-flex>
							<v-flex xs12 sm6 md4 style="font-size:18px;" :class="{'px-5':$vuetify.breakpoint.mdAndUp, 'my-5':$vuetify.breakpoint.smAndDown}">
								<v-layout column class="ftr-socialLinks-wrapper">
									<v-flex class="accent--text" style="margin-bottom:10px;font-size:16px">
										Follow Us
									</v-flex>
									<v-flex>
								<a style="text-decoration:none" href="https://twitter.com/ImagineTechAI" target="_blank" class="mr-2">
									<v-btn icon>
										<v-icon large color="accent">mdi-twitter</v-icon>
									</v-btn>
								</a>
								<a style="text-decoration:none" href="https://www.linkedin.com/company/imaginetechai" target="_blank" class="mx-2">
									<v-btn icon>
										<v-icon large color="accent">mdi-linkedin</v-icon>
									</v-btn>
								</a>
								<a style="text-decoration:none" href="https://www.youtube.com/channel/UCDBcgz40Kwszs0IRwtlC4Qw" target="_blank" class="ml-2">
									<v-btn icon>
										<v-icon large color="accent">mdi-youtube</v-icon>
									</v-btn>
								</a>
							</v-flex>
								<!-- <v-flex class="btn-wrapper">
										<v-btn :ripple="false" text class="white--text" @click="contactUsClicked">Contact Us</v-btn>
									</v-flex>
									<v-flex>
										<router-link to="/about" class="white--text">About Us</router-link>
									</v-flex>
									<v-flex>
										<router-link to="/privacy-policy" class="white--text">Privacy Policy</router-link>
									</v-flex>
									<v-flex>
										<router-link to="/terms-of-use" class="white--text">Terms Of Use</router-link>
									</v-flex>-->
									<!-- <v-flex>
										<router-link to="/blog" class="white--text">Blogs</router-link>
									</v-flex> -->
									<!-- <v-flex>
										<router-link to="/press-release/Imagine-brings-the-power-of-applied-AI-to-businesses-worldwide" class="white--text">Press Release</router-link>
									</v-flex> -->
									<!-- <v-flex>
										<router-link to="" class="white--text">Blog</router-link>
									</v-flex> -->
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex class="mt-15 mb-5">
				<v-divider class="accent"></v-divider>
			</v-flex>
			<v-flex style="font-size:14px" class="white--text" :style="{'text-align':$vuetify.breakpoint.mdAndUp?'center':'center'}">
				Copyright © 2023 Imagine Tech. All rights reserved
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import Logo from '../Logo'
export default {
	name: 'Footer',
	components: {
		Logo
	},
	data() {
		return {
			products: [
				{
					name: 'Cognitive Extractor',
					path: '/solutions/cognitive-extractor'
				},
				{
					name: 'Neural Search',
					path: '/solutions/neural-search'
				},
				{
					name: 'Remote Assist',
					path: '/solutions/remote-assist'
				},
				{
					name: 'Vision Intelligence',
					path: '/solutions/vision-intelligence'
				},
				{
					name: 'Text Intelligence',
					path: '/solutions/text-intelligence'
				}
			],
			industries: [
				{
					title: 'Banking & Financial Services',
					path: '/industry/banking-and-financial'
				},
				{
					title: 'Healthcare',
					path: '/industry/healthcare'
				},
				{
					title: 'E-commerce',
					path: '/industry/e-commerce'
				},
				{
					title: 'Insurance',
					path: '/industry/insurance'
				},
				{
					title: 'Manufacturing',
					path: '/industry/manufacturing'
				},
				{
					title: 'Legal',
					path: '/industry/legal'
				}
			]
		}
	},
	methods: {
		contactUsClicked() {
			document.getElementById('contact-us-form').scrollIntoView({block: "end", behavior: "smooth"})
		},
		scrollMeTo(refName) {
    const element = document.getElementById(refName);
	element.scrollIntoView({ behavior: 'smooth' });
  }
	}
}
</script>

<style lang="css" scoped>
.footer{
	padding: 80px 5vw;
	padding-bottom:30px;
}
a{
	text-decoration: none;
}
.btn-wrapper >>> .v-btn:hover, a:hover{
	text-decoration:underline
}
i:hover{
	transition:0.3 ease-in;
	text-shadow: 0 0 20px rgba(246, 193, 194, 0.6)
}
.btn-wrapper >>> .v-btn{
	text-transform:none;
	padding:0;
	height:auto;
	width:auto;
	font-size:18px;
	vertical-align: baseline;
	text-indent: 0;
	letter-spacing: normal
}
.btn-wrapper >>> .v-btn::before{
	background:transparent
}
.imagine-ftr-txt{
	font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    width: 149px;
}
.imagine-ftrAddr-txt{
	font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}
.ftrNav-links-imagine{
	color: var(--imagine-brand-color, #EDD46F);
	font-family: Jost;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; 
}
 @media only screen and (max-width: 600px) {
	.footer-content-wrapper{
		justify-content: center;
		align-items: center;
	}
	.imagine-ftr-txt{
		text-align: center;
	}
	.imagine-ftrAddr-txt{
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ftr-navLink-wrapper, .ftr-socialLinks-wrapper{
		justify-content: center;
		align-items: center;
	}
 }
</style>
