<template lang="html">
	<v-main id="main-background">
		<NavBar />
		<v-container>
			<v-layout column class="pb-5" style="padding-top:140px;" align-center>
				<v-flex style="font-family:JostBold;font-size:80px;margin-top:50px">
					4<span class="accent--text">0</span>4
				</v-flex>
				<v-flex style="font-family:JostBold;font-size:60px;margin-bottom:50px">
					Not Found
				</v-flex>
			</v-layout>
		</v-container>
		<Footer />
	</v-main>
</template>

<script>
import NavBar from '../Outer/NavBar'
import Footer from '../Outer/Footer'
export default {
	name: 'NotFoundPage',
	components: {
		NavBar,
		Footer
	},
	data() {
		return {
		}
	},
	mounted() {
	}
}
</script>

<style lang="css">
#main-background{
	background:#f5f5f5;
}
</style>
