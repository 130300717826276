<!--<template lang="html">
	<div class="nav-bar white" ref="NavBar" :style="{'padding':$vuetify.breakpoint.smAndUp?'40px 5vw':'20px 5vw'}">
		<div class="nav-bar-logo" :style="{'width':$vuetify.breakpoint.smAndUp?'180px':'40%'}">
			<router-link :to="{ name: 'LandingPage'}" class="black--text">
				<Logo :color="logoColor" />
			</router-link>
		</div>
		<div class="nav-bar-item-container" v-if="$vuetify.breakpoint.mdAndUp">
			<div class="nav-bar-item" v-for="(item, i) in navItems" :key="i">
				<v-btn exact text active-class="btn-link-active" :ripple="false" :to="item.path">
					<div class="text-appear" :style="{'animation-delay': (0.1 * i) + 3.8 + 's'}">
						{{ item.name }}
					</div>
					<div class="on-btn-hover black"></div>
				</v-btn>
			</div>
		</div>
		<div class="nav-bar-item-container" id="nav-bar-menu-mobile" v-else>
			<v-btn icon @click="openNavBarMobile=!openNavBarMobile">
				<v-icon>mdi-menu</v-icon>
			</v-btn>
		</div>
		<div class="nav-bar-mobile white" v-if="$vuetify.breakpoint.smAndDown" :class="{'open-mobile':openNavBarMobile}">
			<div class="nav-bar-item" v-for="(item, i) in navItems" :key="i">
				<v-btn @click="openNavBarMobile=false" exact text active-class="btn-link-active" :ripple="false" :to="item.path">
					<div :class="{'text-appear':openNavBarMobile}" :style="{'animation-delay': (0.1 * i) + 's'}">
						{{ item.name }}
					</div>
					<div class="on-btn-hover black"></div>
				</v-btn>
			</div>
		</div>
	</div>
</template>-->

<template lang="html">
	<div class="nav-bar white" ref="NavBar" :style="{'padding':$vuetify.breakpoint.smAndUp?'40px 5vw':'20px 5vw'}">
		<div class="nav-bar-logo" :style="{'width':$vuetify.breakpoint.smAndUp?'180px':'40%'}">
			<router-link :to="{ name: 'LandingPage'}" class="black--text">
				<Logo :color="logoColor" />
			</router-link>
		</div>
		<div class="nav-bar-item-container" v-if="$vuetify.breakpoint.mdAndUp">
			<div class="nav-bar-item" v-for="(item, i) in navItems" :key="i">
				<v-btn exact text active-class="btn-link-active" :ripple="false">
					<div class="text-appear" :style="{'animation-delay': (0.1 * i) + 3.8 + 's'}">
						<a @click="scrollMeTo(item.path)" class="nav-itemLinks">{{item.name}}</a>
					</div>
					<div class="on-btn-hover black"></div>
				</v-btn>
			</div>
		</div>
		<div class="nav-bar-item-container" id="nav-bar-menu-mobile" v-else>
			<v-btn icon @click="openNavBarMobile=!openNavBarMobile">
				<v-icon>mdi-menu</v-icon>
			</v-btn>
		</div>
		<div class="nav-bar-mobile white" v-if="$vuetify.breakpoint.smAndDown" :class="{'open-mobile':openNavBarMobile}">
			<div class="nav-bar-item" v-for="(item, i) in navItems" :key="i">
				<v-btn @click="openNavBarMobile=false" exact text active-class="btn-link-active" :ripple="false">
					<div :class="{'text-appear':openNavBarMobile}" :style="{'animation-delay': (0.1 * i) + 's'}">
						<a @click="scrollMeTo(item.path)" class="nav-itemLinks">{{item.name}}</a>
					</div>
					<div class="on-btn-hover black"></div>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import Logo from '../Logo'
export default {
	name: 'NavBar',
	components: {
		Logo
	},
	data() {
		return {
			navItems: [
				{
					name: 'About',
					path: 'about'
				},
				{
					name: 'Solutions',
					path: 'solutions'
				},
				{
					name: 'Contact',
					path: 'contact'
				},
				//{
				//	name: 'FAQ',
				//	path: '/faq'
				//},
			],
			prevScroll: null,
			curScroll: null,
			prevDirection: 0,
			openNavBarMobile: false,
			logoColor: 'black'
		}
	},
	// watch:{
	// 	$route (to, from){
	// 		if(to.name === 'LandingPage') {
	// 			this.logoColor = 'white'
	// 		}
	// 		if(from.name === 'LandingPage'){
	// 			this.logoColor = 'black'
	// 		}
	// 	}
	// },
	methods: {
  scrollMeTo(refName) {
    const element = document.getElementById(refName);
	element.scrollIntoView({ behavior: 'smooth' });
  }
},
	mounted() {
		this.prevScroll = window.scrollY || document.documentElement.scrollTop
		// if(this.$route.name === 'LandingPage') {
		// 	this.logoColor = 'white'
		// }
		window.onscroll = () => {
			let direction = 0
			this.openNavBarMobile = false
			this.curScroll = window.scrollY || document.documentElement.scrollTop
			if(this.$route.name === 'LandingPage') {
				/*if(this.curScroll > 0) {
					document.getElementsByClassName('nav-bar')[0].classList.add('white')
					this.logoColor = 'black'
					// document.getElementById('imagine-logo-nav-bar').src = "https://imagine-bucket.s3.us-east-2.amazonaws.com/logo.svg"
					if(this.$vuetify.breakpoint.xsOnly) {
						document.getElementById('nav-bar-menu-mobile').classList.remove('dark-background')
					} else {
						let navButtons = document.getElementsByClassName("nav-bar-item")
						for (let i = 0; i < navButtons.length; i++) {
							navButtons[i].classList.remove('dark-background')
						}
					}
				} else if(this.curScroll === 0) {
					this.logoColor = 'white'
					// document.getElementById('imagine-logo-nav-bar').src = "https://imagine-bucket.s3.us-east-2.amazonaws.com/logo_white.svg"
					document.getElementsByClassName('nav-bar')[0].classList.remove('white')
					if(this.$vuetify.breakpoint.xsOnly) {
						document.getElementById('nav-bar-menu-mobile').classList.add('dark-background')
					} else {
						let navButtons = document.getElementsByClassName("nav-bar-item")
						for (let i = 0; i < navButtons.length; i++) {
							navButtons[i].classList.add('dark-background')
						}
					}
				}*/
			}
			if (this.curScroll > this.prevScroll) {
				//scrolled up
				direction = 2
				this.$store.dispatch('windowBeingScrolled', 2)
			}
			else if (this.curScroll < this.prevScroll) {
				//scrolled down
				direction = 1
				this.$store.dispatch('windowBeingScrolled', 1)
			}
			if (direction !== this.prevDirection) {
				if (direction === 2 && this.curScroll > (document.getElementsByClassName('nav-bar')[0].getBoundingClientRect().height*3/4)) {
					document.getElementsByClassName('nav-bar')[0].classList.add('hide')
					this.prevDirection = direction
				}
				else if (direction === 1) {
					document.getElementsByClassName('nav-bar')[0].classList.remove('hide')
					this.prevDirection = direction
				}
			}
			this.prevScroll = this.curScroll
		}
	}
}
</script>

<style lang="css" scoped>
.nav-bar{
	display:flex;
	justify-content: space-between;
	align-items:center;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:1000;
	transition:0.3s ease-in;
}
.nav-bar-mobile{
	position:absolute;
	top:100%;
	left:0;
	transition:0.3s ease-in-out;
	width:100%;
	overflow:hidden;
	text-align:right;
	transform:scaleY(0);
	transform-origin: center top;
}
.open-mobile{
	transform:scaleY(1);
	padding:15px 0;
	box-shadow:0 3px 3px rgba(0,0,0,0.1);
}
.nav-bar-item-container{
	display:flex;
	justify-content: center;
	align-items:center;
}
.nav-bar-item .v-btn:before{
	background:none
}
.nav-bar-item .v-btn{
	letter-spacing:1px;
	font-size:14px;
	margin:0 20px;
	font-family: JostMedium;
	overflow:hidden
}
.dark-background >>> .v-btn{
	color: #f5f5f5
}
.on-btn-hover{
	position:absolute;
	bottom:0;
	left:0;
	width:0;
	transition:0.2s ease-in;
	height:1.5px;
}
.nav-bar-item .v-btn:hover .on-btn-hover{
	width:100%;
}
.dark-background .on-btn-hover{
	background:#f5f5f5!important
}
.btn-link-active .on-btn-hover{
	width:100%;
}
.hide{
	transform:translateY(-100%)
}
.nav-bar-logo a{
	text-decoration:none
}
.text-appear{
	transform:translateY(150%);
	animation-name:text-appear;
	animation-duration:0.6s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes text-appear {
	from{
		transform:translateY(150%)
	}
	to{
		transform:translateY(0)
	}
}
.nav-itemLinks{
	color: #373737;
	text-decoration: none;
}
</style>
